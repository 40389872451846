import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const ArticleItem = ({ slug, format, fluid, tags, categories, title, copy, customClass }) => {
    let dir = '';
    if (format === 'standard') {
        dir = 'articles'
    } else if (format === 'video') {
        dir = 'videos'
    } else {
        dir = 'shop'
    }
    return (
      <div className={`articleItem ${customClass}`}>
        <Link to={`/${dir}/${slug}`}>
          <div className={format === 'video' ? 'image video' : 'image'}>
              <Img 
                  fluid={{ ...fluid, aspectRatio: 4 / 3 }}
                  alt={title}
              />
              <div className="play">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 200 200">
                      <path fill="#FFF" d="M14 8.377L181.659 100 14 191.623V8.377m-4-6.744v196.734L190 100 10 1.633z"/>
                  </svg>
              </div>
          </div>
        </Link>
          <div className="meta">
            <div className="categories">
              {categories && categories.map(item => {
                return (
                  <Link key={item.slug} to={`/categories/${item.slug}`}>{item.name}</Link>
                )
              })}
            </div>
          <Link to={`/${dir}/${slug}`}>
            <h3 className="title" dangerouslySetInnerHTML={{
              __html: title
            }} />
            {
              customClass === 'hero' ? (
                <div dangerouslySetInnerHTML={{ __html: copy }} />
              ) : null
            }
          </Link>
        </div>
      </div>
    )
}

export default ArticleItem;
