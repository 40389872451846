import React, { Component } from "react"
import { Link, graphql } from 'gatsby'
import { DiscussionEmbed } from "disqus-react";

import RelatedVideoList from '../components/relatedVideoList'
import ArticleItem from '../components/articleItem'
import Layout from "../components/layout"


class VideoPageTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost;
    const disqusShortname = "dabacana";
    const disqusConfig = {
      identifier: post.id,
      title: post.title,
    };
    const allVideos = this.props.data.allWordpressPost.edges;
    const { title, content, categories } = this.props.data.wordpressPost;
    return (
      <Layout>
        <div className="video-container">
          <div id="left" className="column">
          <h1>{title}</h1>
              <div className="categories">
                {categories && categories.map(item => {
                  return (
                    <Link to={`/categories/${item.slug}`}>{item.name}</Link>
                  )
                })}
              </div>
            <div className="video-copy">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className="comments">
            <h4>Comments</h4>
            <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </div>
          </div>
          <div id="right" className="column">
            <RelatedVideoList>
              {
                allVideos.filter(x => x.node.id !== this.props.pageContext.id).map((item) => {
                  return (
                    <ArticleItem
                      key={item.node.id}
                      format="video"
                      fluid={item.node.featured_media.localFile ? item.node.featured_media.localFile.childImageSharp.fluid : ''}
                      slug={item.node.slug}
                      title={item.node.title}
                      categories={item.node.categories}
                    />
                  )
                })
              }
            </RelatedVideoList>
          </div>
        </div>
      </Layout>
    )
  }
}

export default VideoPageTemplate

export const PageQuery = graphql`
  query currentVideoPagePostQuery($id: String!) {
    wordpressPost(id: {eq: $id}) {
      title
      content
      categories {
        name
        slug
      }
      featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
    }
    allWordpressPost(filter: {format: {eq: "video"}}) {
      edges {
        node {
          id
          title
          slug
          status
          template
          format
          content
          categories {
            slug
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`