import React from 'react';

const RelatedVideoList = ({ children, customClassName }) => {
  return (
    <div className={customClassName}>
      {children}
    </div>
  )
}
export default RelatedVideoList;
